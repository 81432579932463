import {compare} from '@/utils/function';

export default {
  namespaced:true,
  state: {
    Message:[],
    GroupMember:{},
    WebstocketStatus:false,
    NeedShowEndPopup:false,
    BrokenNetwork:false
  },
  mutations: {
    // 替换Message数据
    onSetStateMessage(state:any,value:any){
      value.value.forEach((val:any,i:number)=>{
        val.messagelist = [];
        state.Message.push(val);
      });
    },
    // 群系统消息
    onGroupSystemMsg(state:any,MessageArr:any){
      // 如果是304排队进度消息：添加reception_id判断是否是同一个接待的显示
      if(MessageArr[0].content_type === 304 || MessageArr[0].content_type === 302){ MessageArr[0].reception_id = localStorage.getItem('receptionId')};
      let is_exist = true;    // 是否需要新创建消息归类会话
      state.Message.forEach((value:any,index:number) => {
        if(value.opposite_id === MessageArr[0].sender_id || value.opposite_id === MessageArr[0].group_id){
          if(value.messagelist.length === 0){
            console.log('添加1');
            let session = {
              is_me:MessageArr[0].sender_id === localStorage.getItem('uid') ? 'me' : 'other',
              is_read:true,
              ...MessageArr[0]
            };
            value.messagelist.push(session);
          }else if(value.messagelist.length > 0){   
            for(let k=0;k<value.messagelist.length;k++){  
              if(value.messagelist[k].content_type === 304 && MessageArr[0].content_type === 304 && value.messagelist[k].reception_id === MessageArr[0].reception_id){
                value.messagelist[k].content = MessageArr[0].content; // 如果是排队需要进行数据替换
                break;
              }else if(value.messagelist[k].content_type === 302 && MessageArr[0].content_type === 302 && value.messagelist[k].reception_id === MessageArr[0].reception_id){
                value.messagelist[k].content = MessageArr[0].content; // 如果是无客服在线需要进行数据替换
                break;
              }else if(k === value.messagelist.length - 1){
                console.log('添加2');
                let session = {
                  is_me:MessageArr[0].sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                  is_read:true,
                  ...MessageArr[0]
                };
                value.messagelist.push(session);
                break;
              }
            }
          }
          is_exist = false;
        }
      });
      // 创建会话分类 --- 判断是不是群，是群需要加单独的max-seq
      if(is_exist){
        console.log('创建会话分类');
        const session = {
          opposite_id:MessageArr[0].chat_type === 1 ? MessageArr[0].sender_id : MessageArr[0].group_id,
          chat_type:MessageArr[0].chat_type,
          messagelist:[
            {
              is_me:MessageArr[0].sender_id === localStorage.getItem('uid') ? 'me' : 'other',
              is_read:true,
              ...MessageArr[0]
            }
          ]
        };
        state.Message.push(session);
      }
    },
    // 拉取收件箱消息
    onPullInboxMessage(state:any,MessageArr:any){
      let minSeq = localStorage.getItem('min-seq');
      if(MessageArr[0].seq <= Number(minSeq)){
        localStorage.setItem('PullMode','history');
      }
      let chatIdentify = localStorage.getItem('chatIdentify');
      let is_exist = true;    // 是否需要新创建消息归类会话
      state.Message.forEach((value:any,index:number) => {
        if(value.opposite_id === chatIdentify || value.opposite_id === MessageArr[0].group_id){
          if(value.messagelist.length === 0){
            let ls_arr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
            ls_arr.forEach((val:any,idx:number)=>{
              let session = {
                is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                is_read:false,
                ...val
              };
              value.messagelist.push(session);
            })
          }else if(value.messagelist.length > 0){
            if(!value.messagelist[0].seq){
              console.log('检测到了系统消息');
              MessageArr.forEach((val:any,idx:number)=>{
                let session = {
                  is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                  is_read:true, //---
                  ...val
                };
                value.messagelist.unshift(session);
              })
              is_exist = false;
              return;
            }

            // 消息去重
            // value.messagelist.forEach((val:any,idx:number)=>{
            //   MessageArr.forEach((v:any,i:any)=>{
            //     if(val.client_msg_id === v.client_msg_id && val.seq === v.seq || val.incr === v.client_msg_id && val.seq === v.seq){
            //       MessageArr.splice(i,1);
            //       console.log('消息去重触发');
            //     }else{
            //       MessageArr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
            //       let ls_data:any = MessageArr.shift();
            //       for (let x = 0; x < value.messagelist.length; x++) {
            //         if (value.messagelist[x].seq > ls_data.seq){
            //           for(let k=0;k<value.messagelist.length;k++){
            //             if(value.messagelist[k].client_msg_id === ls_data.client_msg_id && value.messagelist[k].seq !== ls_data.seq){
            //               console.log('去重',k);
            //               x--;
            //               if (MessageArr.length === 0) {
            //                 break;
            //               }
            //               ls_data = MessageArr.shift();
            //               return;
            //             }
            //           }
            //           console.log('开始插入');
            //           let session = {
            //             is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            //             is_read:true, // ---
            //             ...ls_data
            //           };
            //           value.messagelist.splice(x,0,session);
            //           x--;
            //           if (MessageArr.length === 0) {
            //             break;
            //           }
            //           ls_data = MessageArr.shift();
            //         }else if(x === value.messagelist.length - 1){
            //           console.log('进入ELSE',value.messagelist.length);
            //           let session = {
            //             is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            //             is_read:true,  // ---
            //             ...ls_data
            //           };
            //           value.messagelist.push(session);
            //           break;
            //         }
            //       }
            //     }
            //   })
            // })

            for(let mindex = 0;mindex < value.messagelist.length;mindex++){
              for(let midx = 0;midx < MessageArr.length;midx++){
                if(value.messagelist[mindex].client_msg_id === MessageArr[midx].client_msg_id || value.messagelist[mindex].incr === MessageArr[midx].client_msg_id && value.messagelist[mindex].seq === MessageArr[midx].seq){
                  console.log('消息去重触发');
                  MessageArr.splice(MessageArr[midx],1);
                  break;
                }else if(mindex === value.messagelist.length - 1){
                  MessageArr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
                  let ls_data:any = MessageArr.shift();
                  for (let x = 0; x < value.messagelist.length; x++) {
                    if (value.messagelist[x].seq > ls_data.seq){
                      console.log('开始插入');
                      let session = {
                        is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                        is_read:false,
                        ...ls_data
                      };
                      value.messagelist.splice(x,0,session);
                      x--;
                      if (MessageArr.length === 0) {
                        break;
                      }
                      ls_data = MessageArr.shift();
                    }else if(x === value.messagelist.length - 1){
                      console.log('进入ELSE');
                      let session = {
                        is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                        is_read:false,
                        ...ls_data
                      };
                      value.messagelist.push(session);
                      break;
                    }
                  }
                }
              }
            }

          }
          is_exist = false;
        }
      });
      // 创建会话分类
      if(is_exist){
        console.log('创建会话分类');
        let arr:any = [];
        let ls_arr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
        ls_arr.forEach((val:any,inx:number)=>{
          let session = {
            is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            is_read:true, //---
            ...val
          };
          arr.push(session);
        })
        const session = {
          opposite_id:arr[0].chat_type === 1 ? chatIdentify : arr[0].group_id,
          messagelist:arr
        };
        state.Message.push(session);
      }
    },
    // 获取最新消息
    onNewMessage(state:any,MessageArr:any){
      let info = MessageArr.slice(-1);
      if(MessageArr[MessageArr.length - 1].chat_type === 1){
        localStorage.setItem('max-seq',MessageArr[MessageArr.length - 1].seq);
      }else if(MessageArr[MessageArr.length - 1].chat_type === 2){
        localStorage.setItem('groupMaxSeq',MessageArr[MessageArr.length - 1].seq);
      }
      let chatIdentify = localStorage.getItem('chatIdentify');
      let is_exist = true;    // 是否需要新创建消息归类会话
      // 删除系统类型消息
      MessageArr.forEach((val:any,idx:number)=>{
        if(val.content_type === 201 || val.content_type === 202){
          MessageArr.splice(idx,1);
        };
      })
      state.Message.forEach((value:any,index:number) => {
        if(value.opposite_id === chatIdentify || value.opposite_id === MessageArr[0].group_id){
          if(value.messagelist.length === 0){
            MessageArr.forEach((val:any,idx:number)=>{
              let session = {
                is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                is_read:false,
                ...val
              };
              value.messagelist.push(session);
            })
          }else if(value.messagelist.length > 0){
            // 消息去重
            // value.messagelist.forEach((val:any,idx:number)=>{
            //   MessageArr.forEach((v:any,i:any)=>{
            //     console.log(val.client_msg_id,v.client_msg_id);
            //     if(val.client_msg_id === v.client_msg_id){
            //       console.log('消息去重触发');
            //     }else if(v.sender_id === localStorage.getItem('uid')){
            //       if(val.seq === null || val.seq === undefined || val.seq === v.seq && val.incr === v.client_msg_id){
            //         val.seq = v.seq;
            //       }
            //     }else{
            //       MessageArr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
            //       let ls_data:any = MessageArr.shift();
            //       for (let x = 0; x < value.messagelist.length; x++) {
            //         if (value.messagelist[x].seq > ls_data.seq){
            //           console.log('开始插入');
            //           let session = {
            //             is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            //             is_read:false,
            //             ...ls_data
            //           };
            //           value.messagelist.splice(x,0,session);
            //           x--;
            //           if (MessageArr.length === 0) {
            //             break;
            //           }
            //           ls_data = MessageArr.shift();
            //         }else if(x === value.messagelist.length - 1){
            //           console.log('进入ELSE');
            //           let session = {
            //             is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            //             is_read:false,
            //             ...ls_data
            //           };
            //           value.messagelist.push(session);
            //           break;
            //         }
            //       }
            //     }
            //   })
            // })

            for(let mindex = 0;mindex < value.messagelist.length;mindex++){
              for(let midx = 0;midx < MessageArr.length;midx++){
                if(value.messagelist[mindex].client_msg_id === MessageArr[midx].client_msg_id){
                  console.log('消息去重触发');
                  break;
                }else if(MessageArr[midx].sender_id === localStorage.getItem('uid')){
                  if(value.messagelist[mindex].seq === null || value.messagelist[mindex].seq === undefined || value.messagelist[mindex].seq === MessageArr[midx].seq && value.messagelist[mindex].incr === MessageArr[midx].client_msg_id){
                    value.messagelist[mindex].seq = MessageArr[midx].seq;
                    if(value.messagelist[mindex].content_type === 103){
                      value.messagelist[mindex].content = MessageArr[midx].content;
                    }
                    break;
                  }
                }else if(mindex === value.messagelist.length - 1){
                  MessageArr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
                  let ls_data:any = MessageArr.shift();
                  for (let x = 0; x < value.messagelist.length; x++) {
                    if (value.messagelist[x].seq > ls_data.seq){
                      console.log('开始插入');
                      let session = {
                        is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                        is_read:false,
                        ...ls_data
                      };
                      value.messagelist.splice(x,0,session);
                      x--;
                      if (MessageArr.length === 0) {
                        break;
                      }
                      ls_data = MessageArr.shift();
                    }else if(x === value.messagelist.length - 1){
                      console.log('进入ELSE');
                      let session = {
                        is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                        is_read:false,
                        ...ls_data
                      };
                      value.messagelist.push(session);
                      break;
                    }
                  }
                }
              }
            }
            
          }
          is_exist = false;
        }
      });
      // 创建会话分类
      if(is_exist){
        console.log('创建会话分类');
        let arr:any = [];
        MessageArr.forEach((val:any,idx:number)=>{
          let session = {
            is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            is_read:false,
            ...val
          };
          arr.push(session);
        })
        const session = {
          opposite_id:arr[0].chat_type === 1 ? chatIdentify : arr[0].group_id,
          messagelist:arr
        };
        state.Message.push(session);
      }
    },
    // 获取未读消息
    onUnreadMessage(state:any,MessageArr:any){
      let chatIdentify = localStorage.getItem('chatIdentify');
      let is_exist = true;    // 是否需要新创建消息归类会话
      state.Message.forEach((value:any,index:number) => {
        if(value.opposite_id === chatIdentify || value.opposite_id === MessageArr[0].group_id){
          if(value.messagelist.length === 0){
            let ls_arr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
            ls_arr.forEach((val:any,idx:number)=>{
              let session = {
                is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                is_read:false,
                ...val
              };
              value.messagelist.push(session);
            })
          }else if(value.messagelist.length > 0){

            // 消息去重
            // value.messagelist.forEach((val:any,idx:number)=>{
            //   MessageArr.forEach((v:any,i:any)=>{
            //     if(val.client_msg_id === v.client_msg_id && val.seq !== v.seq){
            //       console.log('消息去重触发');
            //     }else if(v.sender_id === localStorage.getItem('uid')){
            //       if(val.seq === null || val.seq === undefined && val.incr === v.client_msg_id){
            //         val.seq = v.seq;
            //       }
            //     }else{
            //       MessageArr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
            //       let ls_data:any = MessageArr.shift();
            //       for (let x = 0; x < value.messagelist.length; x++) {
            //         // 拉取未读消息要判断拉取的最大SEQ是否大于当前会话的最小SEQ 如果大于则正常SEQ插入排序 小于则不插入 停止循环拉取
            //         // if(!value.messagelist[0] || !value.messagelist[0].seq){
            //         //   let session = {
            //         //     is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            //         //     is_read:false,
            //         //     ...ls_data
            //         //   };
            //         //   value.messagelist.push(session);
            //         //   x--;
            //         //   if (MessageArr.length === 0) {
            //         //     break;
            //         //   }
            //         //   ls_data = MessageArr.shift();
            //         // }
            //         // ----------------------------------
            //         if(MessageArr[MessageArr.length - 1].seq < value.messagelist[0].seq){
            //           localStorage.setItem('NeedPullUnreadMessage','false');
            //           return;
            //         }
            //         if (value.messagelist[x].seq > ls_data.seq){
            //           console.log('开始插入');
            //           let session = {
            //             is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            //             is_read:false,
            //             ...ls_data
            //           };
            //           value.messagelist.splice(x,0,session);
            //           x--;
            //           if (MessageArr.length === 0) {
            //             break;
            //           }
            //           ls_data = MessageArr.shift();
            //         }else if(x === value.messagelist.length - 1){
            //           console.log('进入ELSE');
            //           let session = {
            //             is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            //             is_read:false,
            //             ...ls_data
            //           };
            //           value.messagelist.push(session);
            //           break;
            //         }
            //       }
            //     }
            //   })
            // })

            for(let mindex = 0;mindex < value.messagelist.length;mindex++){
              for(let midx = 0;midx < MessageArr.length;midx++){
                if(value.messagelist[mindex].client_msg_id === MessageArr[midx].client_msg_id){
                  console.log('消息去重触发');
                  break;
                }else if(MessageArr[midx].sender_id === localStorage.getItem('uid')){
                  if(value.messagelist[mindex].seq === null || value.messagelist[mindex].seq === undefined || value.messagelist[mindex].seq === MessageArr[midx].seq && value.messagelist[mindex].incr === MessageArr[midx].client_msg_id){
                    value.messagelist[mindex].seq = MessageArr[midx].seq;
                    break;
                  }
                }else if(mindex === value.messagelist.length - 1){
                  MessageArr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
                  let ls_data:any = MessageArr.shift();
                  for (let x = 0; x < value.messagelist.length; x++) {
                    if(MessageArr[MessageArr.length - 1].seq < value.messagelist[0].seq){
                      localStorage.setItem('NeedPullUnreadMessage','false');
                      return;
                    }
                    if (value.messagelist[x].seq > ls_data.seq){
                      console.log('开始插入');
                      let session = {
                        is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                        is_read:false,
                        ...ls_data
                      };
                      value.messagelist.splice(x,0,session);
                      x--;
                      if (MessageArr.length === 0) {
                        break;
                      }
                      ls_data = MessageArr.shift();
                    }else if(x === value.messagelist.length - 1){
                      console.log('进入ELSE');
                      let session = {
                        is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                        is_read:false,
                        ...ls_data
                      };
                      value.messagelist.push(session);
                      break;
                    }
                  }
                }
              }
            }

          }
          is_exist = false;
        }
      });
      // 创建会话分类
      if(is_exist){
        console.log('创建会话分类');
        let arr:any = [];
        let ls_arr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
        ls_arr.forEach((val:any,inx:number)=>{
          let session = {
            is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            is_read:false,
            ...val
          };
          arr.push(session);
        })
        const session = {
          opposite_id:arr[0].chat_type === 1 ? chatIdentify : arr[0].group_id,
          messagelist:arr
        };
        state.Message.push(session);
      }
    },
    // 推入对应消息 --- 接收
    onPushMessage(state:any,MessageArr:any){
      let is_exist = true;    // 是否需要新创建消息归类会话
      state.Message.forEach((value:any,index:number) => {
        if(value.opposite_id === MessageArr[0].sender_id || value.opposite_id === MessageArr[0].group_id){
          if(value.messagelist.length === 0){
            let session = {
              is_me:MessageArr[0].sender_id === localStorage.getItem('uid') ? 'me' : 'other',
              is_read:false,
              ...MessageArr[0]
            };
            value.messagelist.push(session);
          }else if(value.messagelist.length > 0){
            if(!value.messagelist[value.messagelist.length - 1].seq){
              console.log('检测到了系统消息');
              MessageArr.forEach((val:any,idx:number)=>{
                let session = {
                  is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                  is_read:false,
                  ...val
                };
                value.messagelist.push(session);
              })
              is_exist = false;
              return;
            }
            // 消息去重
            value.messagelist.forEach((val:any,idx:number)=>{
              MessageArr.forEach((v:any,i:any)=>{
                //  && val.seq !== v.seq
                if(val.client_msg_id === v.client_msg_id){
                  console.log('消息去重触发');
                }else{
                  MessageArr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
                  let ls_data:any = MessageArr.shift();
                  for (let x = 0; x < value.messagelist.length; x++) {
                    if (value.messagelist[x].seq > ls_data.seq){
                      console.log('开始插入');
                      let session = {
                        is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                        is_read:false,
                        ...ls_data
                      };
                      value.messagelist.splice(x,0,session);
                      x--;
                      if (MessageArr.length === 0) {
                        break;
                      }
                      ls_data = MessageArr.shift();
                    }else if(x === value.messagelist.length - 1){
                      console.log('进入ELSE');
                      let session = {
                        is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
                        is_read:false,
                        ...ls_data
                      };
                      value.messagelist.push(session);
                      break;
                    }
                  }
                }
              })
            })
          }
          is_exist = false;
        }
      });
      // 创建会话分类 --- 判断是不是群，是群需要加单独的max-seq
      if(is_exist){
        console.log('创建会话分类');
        const session = {
          opposite_id:MessageArr[0].chat_type === 1 ? MessageArr[0].sender_id : MessageArr[0].group_id,
          chat_type:MessageArr[0].chat_type,
          messagelist:[
            {
              is_me:MessageArr[0].sender_id === localStorage.getItem('uid') ? 'me' : 'other',
              is_read:false,
              ...MessageArr[0]
            }
          ]
        };
        state.Message.push(session);
      }
    },
    // 推入对应消息 --- 发送
    onSendPushMessage(state:any,value:any){
      state.Message.forEach((val:any,i:number) => {
        if(val.opposite_id === value.receiver_id || val.opposite_id === value.group_id){
          val.messagelist.push({
            is_me:value.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
            ...value
          })
        }
      });
    },
    // 创建会话临时消息
    onCreateSession(state:any,value:any){
      state.Message.push(value);
    },
    // 群SEQ赋值
    ongroupSeqAssignment(state:any,value:any){
      for(let key in value){
          localStorage.setItem('groupMaxSeq',value[key].max_seq);
          localStorage.setItem('groupMinSeq',value[key].min_seq);
          for(let i = 0;i<state.Message.length;i++){
            if(state.Message[i].opposite_id === key){
              if(value[key].max_seq && value[key].min_seq){
                state.Message[i].max_seq = value[key].max_seq;
                state.Message[i].min_seq = value[key].min_seq;
                localStorage.setItem('groupMaxSeq',value[key].max_seq);
                localStorage.setItem('groupMinSeq',value[key].min_seq);
              }else{
                state.Message[i].max_seq = 0;
                state.Message[i].min_seq = 0;
              }
            }
          }
      }
    },
    // 重置Message数据
    onReset(state:any){
      state.Message = [];
    },
    // 群成员昵称赋值
    onGroupMember(state:any,value:any){
      state.GroupMember[value.id] = value;
    },
    // 重置Message数据
    onMemberReset(state:any){
      state.GroupMember = {};
    },
    // 修改群系统消息按钮状态
    onReviseStatus(state:any,value:any){
      for(let i = 0;i<state.Message.length;i++){
        if(state.Message[i].opposite_id === value.group_id){
          for(let k = 0;k<state.Message[i].messagelist.length;k++){
            if(value.client_msg_id === state.Message[i].messagelist[k].client_msg_id){
              state.Message[i].messagelist[k] = {...value,disable:true};
              break;
            }
          }
          break;
        }
      }
    },
    // 评论被系统自动评价，进行自动置灰
    onCommentGrey(state:any,value:any){
      for(let i = 0;i<state.Message.length;i++){
        if(state.Message[i].opposite_id == value.group_id){
          for(let k = 0;k<state.Message[i].messagelist.length;k++){
            if(state.Message[i].messagelist[k].content_type === 202){
              if(value.reception_id === JSON.parse(state.Message[i].messagelist[k].content).reception_id){
                state.Message[i].messagelist[k] = {...state.Message[i].messagelist[k],disable:true};
                break;
              }
            }
          }
          break;
        }
      }
    },
    // 修改webstocket连接状态
    onSetWebstocketStatus(state:any,bool:boolean){
      //  console.log('准备修改',state.WebstocketStatus);
      state.WebstocketStatus = bool;
      // console.log('修改成功',state.WebstocketStatus);
    },
    // 修改客服申请结束会话弹窗是否显示
    onSetEndPopupShow(state:any,bool:boolean){
      state.NeedShowEndPopup = bool;
    },
    // 是否断网状态修改
    onSetNetworkStatus(state:any,bool:boolean){
      state.BrokenNetwork = bool;
    }
  }
}


// Message:{   
//   customerList:[   // 客服消息
//     {
//         is_me:'other',                                                   // 谁发送的消息
//         content:'[&E3&]库存信息[&E3&]',                                   //消息体
//         avater:'https://fastly.jsdelivr.net/npm/@vant/assets/logo.png',  // 用户头像
//         content_type:101,                                                     // 消息类型
//     }
//   ],
//   messageList:[   // 列表消息
    
//   ]
// }

// val.messagelist.push({
//   is_me:value.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
//   is_read:false,
//   ...value
// })


// 消息去重
// val.messagelist.forEach((v:any,i:number)=>{
//   value.forEach((m:any,x:number)=>{
//     if(v.client_msg_id === m.client_msg_id && v.seq !== m.seq){
//       console.log('消息去重');
//     }else{
//       // 消息插入 --- SEQ排序
//       value = value.sort((item1:any, item2:any) => item1.seq - item2.seq);
//       let item:any = value.shift();
//       for (let i = 0; i < val.messagelist.length; i++) {
//         if (val.messagelist[i].seq > item.seq) {
//           console.log('执行了1')
//           val.messagelist.splice(i, 0, item);
//           i--;
//           if (value.length === 0) {
//             break;
//           }
//           item = value.shift();
//         }else if(i === val.messagelist.length - 1){
//           console.log('执行了2')
//           val.messagelist.push(item,...value);
//         }
//       }
//     }
//   })
// })


// 拉取收件箱消息
// onPullInboxMessage(state:any,MessageArr:any){
//   let minSeq = localStorage.getItem('min-seq');
//   if(MessageArr[0].seq <= Number(minSeq)){
//     localStorage.setItem('PullMode','history');
//   }
//   let chatIdentify = localStorage.getItem('chatIdentify');
//   let is_exist = true;    // 是否需要新创建消息归类会话
//   state.Message.forEach((value:any,index:number) => {
//     if(value.opposite_id === chatIdentify){
//       if(value.messagelist.length === 0){
//         let ls_arr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
//         ls_arr.forEach((val:any,idx:number)=>{
//           let session = {
//             is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
//             is_read:false,
//             ...val
//           };
//           value.messagelist.push(session);
//         })
//       }else if(value.messagelist.length > 0){
//         // 消息去重
//         value.messagelist.forEach((val:any,idx:number)=>{
//           MessageArr.forEach((v:any,i:any)=>{
//             console.log('-----------------------',v);
//             if(val.client_msg_id === v.client_msg_id && val.seq !== v.seq){
//               console.log('消息去重触发1',i);
//               MessageArr.splice(i,1);
//             }else if(val.client_msg_id === v.client_msg_id && val.seq === v.seq){
//               console.log('消息去重触发2',i);
//               MessageArr.splice(i,1);
//             }else{
//               MessageArr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
//               let ls_data:any = MessageArr.shift();
//               for (let x = 0; x < value.messagelist.length; x++) {
//                 if (value.messagelist[x].seq > ls_data.seq){
//                   console.log('开始插入');
//                   let session = {
//                     is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
//                     is_read:false,
//                     ...ls_data
//                   };
//                   value.messagelist.splice(x,0,session);
//                   x--;
//                   if (MessageArr.length === 0) {
//                     break;
//                   }
//                   ls_data = MessageArr.shift();
//                 }else if(x === value.messagelist.length - 1){
//                   console.log('进入ELSE',value.messagelist.length);
//                   let session = {
//                     is_me:ls_data.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
//                     is_read:false,
//                     ...ls_data
//                   };
//                   value.messagelist.push(session);
//                   break;
//                 }
//               }
//             }
//           })
//         })
//       }
//       is_exist = false;
//     }
//   });
//   // 创建会话分类
//   if(is_exist){
//     console.log('创建会话分类');
//     let arr:any = [];
//     let ls_arr = MessageArr.sort((pre:any,later:any) => pre.seq - later.seq);
//     ls_arr.forEach((val:any,inx:number)=>{
//       let session = {
//         is_me:val.sender_id === localStorage.getItem('uid') ? 'me' : 'other',
//         is_read:false,
//         ...val
//       };
//       arr.push(session);
//     })
//     const session = {
//       opposite_id:chatIdentify,
//       messagelist:arr
//     };
//     state.Message.push(session);
//   }
// },


// 群系统消息
// onGroupSystemMsg(state:any,MessageArr:any){
//   // 如果是304排队进度消息：添加reception_id判断是否是同一个接待的显示
//   if(MessageArr[0].content_type === 304 || MessageArr[0].content_type === 302){ MessageArr[0].reception_id = localStorage.getItem('receptionId')};
//   let is_exist = true;    // 是否需要新创建消息归类会话
//   state.Message.forEach((value:any,index:number) => {
//     if(value.opposite_id === MessageArr[0].sender_id || value.opposite_id === MessageArr[0].group_id){
//       if(value.messagelist.length === 0){
//         let session = {
//           is_me:MessageArr[0].sender_id === localStorage.getItem('uid') ? 'me' : 'other',
//           is_read:true,
//           ...MessageArr[0]
//         };
//         value.messagelist.push(session);
//       }else if(value.messagelist.length > 0){   
//         for(let k=0;k<value.messagelist.length;k++){  
//           if(value.messagelist[k].content_type === 304 && MessageArr[0].content_type === 304 && value.messagelist[k].reception_id === MessageArr[0].reception_id){
//             value.messagelist[k].content = MessageArr[0].content; // 如果是排队需要进行数据替换
//             break;
//           }else if(value.messagelist[k].content_type === 302 && MessageArr[0].content_type === 302 && value.messagelist[k].reception_id === MessageArr[0].reception_id){
//             value.messagelist[k].content = MessageArr[0].content; // 如果是无客服在线需要进行数据替换
//             break;
//           }else if(k === value.messagelist.length - 1){
//             let session = {
//               is_me:MessageArr[0].sender_id === localStorage.getItem('uid') ? 'me' : 'other',
//               is_read:true,
//               ...MessageArr[0]
//             };
//             value.messagelist.push(session);
//             break;
//           }
//         }
//       }
//       is_exist = false;
//     }
//   });
//   // 创建会话分类 --- 判断是不是群，是群需要加单独的max-seq
//   if(is_exist){
//     console.log('创建会话分类');
//     const session = {
//       opposite_id:MessageArr[0].chat_type === 1 ? MessageArr[0].sender_id : MessageArr[0].group_id,
//       chat_type:MessageArr[0].chat_type,
//       messagelist:[
//         {
//           is_me:MessageArr[0].sender_id === localStorage.getItem('uid') ? 'me' : 'other',
//           is_read:true,
//           ...MessageArr[0]
//         }
//       ]
//     };
//     state.Message.push(session);
//   }
// },